export const loginNameSpace = {
  en: {
    LOGIN_TITLE: 'Log In to Your Account',
    FORGOT_MESSAGE: 'Forgot yout password?',
    DONT_HAVE_MESSAGE: "Don't have an <strong>areeba id</strong>",
    BRANDING_MESSAGE:
      'Explore our guides and examples to integrate areeba APIs and SDKs.',
    FORGOT_PASSWORD: 'Forgot Password',
    REMEMBER_ME: 'Remember Me',
    INVALID_USERNAME: 'Invalid username or password',
  },
  ar: {},
};
