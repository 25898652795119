import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { Login } from './login.type';

const initialState: Login = {};

const loginApi = createAsyncThunk(
  'login/loginApi',
  async ({ username, password }: any, { rejectWithValue }: any) => {
    try {
      const body = {
        email: username,
        password: password,
        appName: 'portal',
      } as any;
      const res = await axios.post(
        'https://sandbox.areeba.com/identity/portal-login',
        body,
        {
          headers: {
            Authorization: 'Bearer 123',
            accept: 'application/vnd.areeba.user+json; version=2.0',
          },
        },
      );
      return res?.data;
    } catch (e) {
      return rejectWithValue(e?.response?.data);
    }
  },
);

const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    setLogin: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },
});

export const loginReducer = loginSlice.reducer;

export const loginActions = { ...loginSlice.actions, loginApi };
