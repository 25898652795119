import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Layout, Space } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';

import styles from './login.module.css';
import { InputPassword, InputText } from '&styled/input/input.component';
import { H1, SubTitle } from '&styled/typography/typography.component';
import { PrimaryButton } from '&styled/button/button.component';
import { loginActions } from './login.slice';

import { ReactComponent as Logo } from '&assets/images/logo.svg';
import Developers from '&assets/images/developers.png';

type ReduxProps = ConnectedProps<typeof connector>;

const LoginComponent = (props: ReduxProps) => {
  const { loginApi } = props;
  const { t } = useTranslation(['login']);
  const { promiseInProgress } = usePromiseTracker();

  const initialValues = {
    email: undefined,
    password: undefined,
  };

  const [errorVisible, setErrorVisible] = useState<boolean>(false);

  useEffect(() => {}, []);

  return (
    <Layout className={styles.layout}>
      <Row justify="center">
        <Col xs={24} sm={22} md={20} lg={18} xl={16} xxl={14}>
          <Row className={styles.container} align="top">
            <Col className={styles.branding} sm={24} md={11}>
              <Space
                direction="vertical"
                align="center"
                className={styles.space}
              >
                <Logo />
                <Row justify="center">
                  <img className={styles.art} src={Developers} alt="" />
                </Row>
                <Row justify="center">
                  <SubTitle>{t('BRANDING_MESSAGE')}</SubTitle>
                </Row>
              </Space>
            </Col>
            <Col className={styles.form} sm={24} md={13}>
              <Form
                name="login"
                layout="vertical"
                requiredMark={'optional'}
                initialValues={initialValues}
                onFinish={async (values: any) => {
                  const res = await loginApi({
                    username: values.email,
                    password: values.password,
                  });
                  if (res?.payload?.token) {
                    window.location.replace(
                      'https://developers.areeba.com/?auth_token=' +
                        res?.payload?.token,
                    );
                  } else {
                    setErrorVisible(true);
                  }
                }}
              >
                <Form.Item>
                  <H1>{t('LOGIN_TITLE')}</H1>
                </Form.Item>
                <Form.Item
                  name="email"
                  label={t('common:EMAIL_LABEL')}
                  rules={[
                    {
                      required: true,
                      message: t('common:REQUIRED_ERROR_MESSAGE', {
                        fieldName: t('common:EMAIL_LABEL').toLowerCase(),
                      }),
                    },
                    {
                      type: 'email',
                      message: t('common:INVALID_ERROR_MESSAGE', {
                        fieldName: t('common:EMAIL_LABEL').toLowerCase(),
                      }),
                    },
                  ]}
                >
                  <InputText
                    autoComplete="email"
                    placeholder={t('common:EMAIL_PLACEHOLDER')}
                  />
                </Form.Item>
                <Form.Item
                  label={t('common:PASSWORD_LABEL')}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t('common:REQUIRED_ERROR_MESSAGE', {
                        fieldName: t('common:PASSWORD_LABEL'),
                      }),
                    },
                  ]}
                >
                  <InputPassword
                    autoComplete="current-password"
                    placeholder={t('common:PASSWORD_PLACEHOLDER')}
                  />
                </Form.Item>
                <Form.Item />
                <Form.Item>
                  <PrimaryButton loading={promiseInProgress} htmlType="submit">
                    {t('common:LOG_IN')}
                  </PrimaryButton>
                </Form.Item>
                {errorVisible && (
                  <p className={styles.errorTextStyle}>
                    {t('INVALID_USERNAME')}
                  </p>
                )}
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  loginApi: loginActions.loginApi,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const LoginComponentRedux = connector(LoginComponent);

export { LoginComponentRedux as LoginComponent };
