export const commonNameSpace = {
  en: {
    EMAIL_LABEL: 'Email address',
    REQUIRED_ERROR_MESSAGE: 'Please provide {{fieldName}}!',
    INVALID_ERROR_MESSAGE: 'Please make sure {{fieldName}} is valid!',
    EMAIL_PLACEHOLDER: 'someone@example.com',
    PASSWORD_PLACEHOLDER: '********',
    PASSWORD_LABEL: 'Password',
    LOG_IN: 'Log In',
    REGISTER: 'Register',
  },
  ar: {},
};
