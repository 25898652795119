import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { LoginComponent } from '&features/login/login.component';

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" component={LoginComponent} />
      </Switch>
    </Router>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
const AppRouteRedux = connector(AppRouter);

export { AppRouteRedux as AppRouter };
