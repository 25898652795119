import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';

import './App.css';
import 'antd/dist/antd.css';

import { AppRouter } from './App.router';

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {}, [i18n.language]);

  return (
    <ConfigProvider direction={i18n.dir()}>
      <AppRouter />
    </ConfigProvider>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const AppRedux = connector(App);

export { AppRedux as App };
