import { combineReducers, Reducer } from 'redux';

import { loginReducer } from '&features/login/login.slice';

const createRootReducer = (routerReducer: Reducer) =>
  combineReducers({
    router: routerReducer,
    login: loginReducer,
  });

export default createRootReducer;
