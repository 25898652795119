import React from 'react';
import { DatePicker, Input } from 'antd';
import { InputProps } from 'antd/lib/input';

import styles from './input.module.css';

import { ReactComponent as Calendar } from '&assets/images/ic-calendar.svg';

const InputText = (props: InputProps) => (
  <Input className={styles.text} {...props} />
);

const InputPassword = (props: InputProps) => (
  <Input.Password className={styles.text} {...props} />
);

const InputDate = (props: any) => (
  <DatePicker className={styles.text} suffixIcon={<Calendar />} {...props} />
);

export { InputText, InputPassword, InputDate };
